<template>
    <div class="content-menu" v-if="subpages.length" key="infocenterMenu">
        <ul class="list list--chapter">
            <li class="list-item list-item--back" v-if="$route.params.slug!== 'alles'">
                <router-link :to="{path:'/infocenter'}">
                    <inline-svg class="icon" :src="require('@/assets/images/ic_back.svg')" />
                    <span>zurück</span>
                </router-link>
            </li>
            <li class="list-item"
                v-for="(article,idx) in subpages"
                v-bind:key="idx"
                :class="{'list-item--highlight': article.layout === 1}"
            >
                <a :href="article.url" v-if="article.extern" target="_blank" @click.prevent="openModal(article.url)">
                    <span v-html="article.title" />
                    <inline-svg class="icon" :src="require('@/assets/images/ic_extern.svg')" />
                </a>
                <router-link v-else-if="article.slug" :to="{ path : '/infocenter/' + infoSlug(article)}">
                    <span>{{ article.title }}</span>
                    <inline-svg class="icon" :src="require('@/assets/images/ic_next.svg')" />
                </router-link>
                <span v-if="!article.slug">{{ article.title }}</span>
            </li>

            <li class="list-item">
                <router-link :to="{path:'/infocenter/la_standorte'}">
                    <span>Standorte des Landratsamtes NEW</span>
                    <inline-svg class="icon" :src="require('@/assets/images/ic_next.svg')" />
                </router-link>
            </li>
            <li class="list-item">
                <router-link :to="{path:'/infocenter/new_gemeinden'}">
                    <span>Rathäuser der Gemeinden in NEW</span>
                    <inline-svg class="icon" :src="require('@/assets/images/ic_next.svg')" />
                </router-link>
            </li>
            <li class="list-item">
                <router-link :to="{path:'/infocenter/wetter_verkehr'}">
                    <span>Aktuelle Wetter- und Verkehrslage in NEW</span>
                    <inline-svg class="icon" :src="require('@/assets/images/ic_next.svg')" />
                </router-link>
            </li>
            </ul>

        <div>
            <ul class="list list--dropdown">
                <li class="list-item">
                    <span><inline-svg :src="require('@/assets/images/ic_facebook.svg')" width="36"/><span>Facebook</span>
                    <inline-svg :src="require('@/assets/images/ic_chevron_down.svg')" width="12"/>
                    </span>
                    <ul>
                        <li class="list-item">
                            <a href="https://www.facebook.com/landratsamtnew" target="_blank">
                                <span>Landratsamt Neustadt an der Waldnaab</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.facebook.com/newperspektiven" target="_blank">
                                <span>new perspektiven</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.facebook.com/derbockl" target="_blank">
                                <span>der Bockl</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.facebook.com/newbaxi" target="_blank">
                                <span>new Baxi</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.facebook.com/newurlaub" target="_blank">
                                <span>new-urlaub.de</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="list-item">
                    <span><inline-svg :src="require('@/assets/images/ic_instagram.svg')" width="36"/><span>Instagram</span>
                    <inline-svg :src="require('@/assets/images/ic_chevron_down.svg')" width="12"/></span>

                    <ul>
                        <li class="list-item">
                            <a href="https://www.instagram.com/newperspektiven/" target="_blank">
                                <span>newperspektiven</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.instagram.com/derbockl/" target="_blank">
                                <span>der Bockl</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.instagram.com/new.baxi/" target="_blank">
                                <span>new.baxi</span>
                            </a>
                        </li>
                        <li class="list-item">
                            <a href="https://www.instagram.com/newurlaub/" target="_blank">
                                <span>newurlaub</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="list-item">
                    <span><inline-svg :src="require('@/assets/images/ic_youtube.svg')" width="36"/><span>Youtube</span>
                    <inline-svg :src="require('@/assets/images/ic_chevron_down.svg')" width="12"/></span>
                    <ul>
                        <li class="list-item">
                            <a href="https://www.youtube.com/channel/UCPllnH4Pe8XL4IlsgKLGepA" target="_blank">
                                <span> Landkreis Neustadt an der Waldnaab</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="list-item">
                    <span><inline-svg :src="require('@/assets/images/ic_linkedin.svg')" width="36"/><span>LinkedIn</span>
                    <inline-svg :src="require('@/assets/images/ic_chevron_down.svg')" width="12"/></span>
                    <ul>
                        <li class="list-item">
                            <a href="https://de.linkedin.com/company/landkreis-neustadt-an-der-waldnaab" target="_blank">
                                <span>Landkreis Neustadt an der Waldnaab</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="list-item">
                    <span><inline-svg :src="require('@/assets/images/ic_pinterest.svg')" width="36"/><span>Pinterest</span>
                    <inline-svg :src="require('@/assets/images/ic_chevron_down.svg')" width="12"/></span>
                    <ul>
                        <li class="list-item">
                            <a href="https://www.pinterest.de/newurlaub/_created/" target="_blank">
                                <span>new-urlaub.de</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            
        </div>

    </div>
</template>
<script>
import api, { ApiDefaults } from '../utils/api_call';
import debounce from "lodash.debounce";
import EventsBus from '../utils/EventsBus';

export default {
	name: 'InfocenterMenu',
    data (){
		return {
			subpages: false
		}
    },
    methods:{
		openModal: function(url){
			EventsBus.trigger('modal:open',url);
        },
		infoSlug : function ( article ) {
			article = Object.assign({},article);
			let slug = article.uid + '' + article.slug.replaceAll('/','_');
			return slug;
        },
		fetchData: debounce(function () {

			let sourceId = 10;

			// if ( this.$route.params.slug !== 'alles' ) {
			// 	sourceId = this.$route.params.slug.split('_');
			// 	sourceId = sourceId[ 0 ];
			// }

			let apiParams = Object.assign({}, ApiDefaults, {params: {id: sourceId}});
			api(apiParams)
				.then(response => {
					if ( response.status === 200 ) {
						this.subpages = false;

						if ( Object.prototype.hasOwnProperty.call(response.data, 'menu') ) {
							let pageLinks = response.data.menu.pages;
							this.subpages = pageLinks;
						}
					}
				});
		}, 125)
    },
	beforeMount() {
		this.fetchData();
	},
};
</script>
